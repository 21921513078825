



























































































































  import Vue from "vue";
  import Layout from "@/router/layouts/main.vue";
  import CzUsersSearchBar from "@/components/cz-users-search-bar.vue";
  import AddNewUserAdminModal from "@/components/modals/AddNewUserAdminModal.vue";
  import ClientZoneTableItem from "@/components/client-zone-users/ClientZoneUsersTableItem.vue";
  import ValidationWarningModal from "@/components/modals/ValidationWarningModal.vue";
  import {mapActions, mapGetters} from "vuex";
  import {UserData} from "@/types";
  import filterCircleIcon from "@iconify-icons/ion/filter-circle";
  import vSelect from "vue-select";
  import router from "@/router";

  export default Vue.extend({
    components: {
      Layout,
      vSelect,
      "cz-users-search-bar": CzUsersSearchBar,
      "add-new-user-admin-modal": AddNewUserAdminModal,
      "client-zone-users-table-item": ClientZoneTableItem,
      "validation-warning-modal": ValidationWarningModal
    },

    data() {
      return {
        title: "Client zone users",
        currentPage: 1,
        perPage: 10,
        userAuthorisationsVenues: [],
        activeFilter: false,
        randomId: Math.floor(Math.random() * 100000),
        userIsOwnerOfVenueOwner: false,
        icons: {
          home: filterCircleIcon,
        },
        company:"",
        role:"",
        status:"",
        mockUsers:[
    {
        "id": 1360,
        "email": "mail1@mail.sk",
        "first_name": "name 1",
        "last_name": "last name 1",
        "job_position": null,
        "phone_number": null,
        "active": true,
        "created_at": 1629363712,
        "roles": [
            {
                "id": 1,
                "name": "Admin",
                "count": 2
            },
        ],
        "venueAuthorizedToUser": [
            {
                "roleId": 4,
                "id": 3204,
                "name": "venue 1",
                "address1": "adress",
                "city": "City",
            },
            {
                "roleId": 4,
                "id": 3206,
                "name": "venue 2",
                "address1": "adress 2",
                "city": "City",
            },

        ]
    },
    {
        "id": 1161,
        "email": "mail2@mail.sk",
        "first_name": "name 2",
        "last_name": "last name 2",
        "job_position": "manager",
        "phone_number": "564564564",
        "image_last_updated": 1629385108,
        "active": true,
        "created_at": 1627310808,
        "roles": [
            {
                "id": 2,
                "name": "Venue Manager",
                "count": 7
            },
        ],
        "venueAuthorizedToUser": [
            {
                "roleId": 4,
                "id": 3204,
                "name": "venue 1",
                "address1": "adress",
                "city": "City",
            },
            {
                "roleId": 4,
                "id": 3206,
                "name": "venue 2",
                "address1": "adress 2",
                "city": "City",
            },

        ]
    }
]
      }
    },

    // async created(): Promise<void> {
    //   await this.checkLoggedUserRolePermission({ permissionId: 11 });
    //   await this.checkLoggedUserRolePermission({permissionId: 12});
    // },

    async mounted(): Promise<void> {
      await this.setUsers();
      this.fetchUsersImages();
    },

    computed: {
      ...mapGetters("userModule", {
        users: "GET_USERS",
        hasManageUserPermissions: "GET_MANAGE_USER_PERMISSION",
        hasManageRolePermissions: "GET_ROLE_MANAGEMENT_PERMISSION"
      }),
      ...mapGetters("companyModule", {
        companyId: "GET_COMPANY_ID"
      }),
      totalUsers(): number {
        return this.users.length;
      },
      usersList(): Array<UserData> {
        this.users.map(user =>{
          user.roles.map((role,index) =>{
            if(role.id === 5 && user.roles.length > 1){
              user.roles.splice(index, 1)
            }
          })
        })
        return this.users.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
      }
    },

    methods: {
      ...mapActions("userModule", {
        // setUsersSummary: "FETCH_USERS_ACCESS_RIGHTS_SUMMARY",
        setUserAuthorisations: "FETCH_USER_AUTHORISATIONS",
        setUsers: "FETCH_USERS",
        setUserRoles: "FETCH_USER_ROLES",
        filterActiveUsers: "FILTER_ACTIVE_USERS"
      }),
      ...mapActions("globalModule", {
        setImage: "FETCH_ENTITY_IMAGE"
      }),
      ...mapActions("userModule", {
        checkLoggedUserRolePermission: "CHECK_LOGGED_USER_ROLE_PERMISSION"
      }),
      emitUsersImages(): void {
        this.currentPage = 1;
        this.fetchUsersImages();
      },
      fetchUsersImages(): void {
        for (let i = 0; i < this.users.length; i++) {
          const user: UserData = this.users[i];

          if (!!user.image_last_updated) {
            this.setImage({id: user.id, imageType: "clientzone_user", multi: true});
          }
        }
      },
      filterUserListByStatus(): void {
        this.currentPage = 1;
        this.filterActiveUsers(this.activeFilter)
      },
      isUserOwner(value: boolean): void {
        this.userIsOwnerOfVenueOwner = value;
      }
    },

    watch: {
      companyId: {
        async handler(newValue: number, oldValue: number): Promise<void> {
          if (newValue != oldValue) {
            await this.setUsers();
            this.fetchUsersImages();
          }
        }
      },
      userIsOwnerOfVenueOwner: {
        handler(value: boolean): void {
          if (value) {
            this.$bvModal.show("validation-warning-modal" + this.randomId);
            this.userIsOwnerOfVenueOwner = false;
          }
        }
      }
    }
  })
